<template>
	<edit-template style="padding: 0 16rem;min-height: calc(100vh - 36rem - 76rem);background: #F9FAFD" @confirm="submitted" @cancel="cancel" :cancelRemind="false" confirmText="提交" cancelText="取消">
		
    <div style="width: 100%;height: 32rem;background: rgba(170,196,255,0.2);border-radius: 4rem 4rem 4rem 4rem;display: flex;align-items: center;">
      <i class="el-icon-warning" style="margin-left: 25rem;margin-right: 15rem"></i>
      <div style="font-size: 14rem;font-weight: normal;color: #666666;">注意！任务一旦新增，不可修改</div>
    </div>

    <div class="body">
      <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="180rem">
        <el-form-item label="任务名称" prop="name">
          <div style="display: flex;width: 33%">
            <el-input v-model="form.name" placeholder="请输入任务名称" style="flex: 1"></el-input>
          </div>
        </el-form-item>
        <el-form-item label="本次任务结束时间" prop="end_at">
          <el-date-picker v-model="form.end_at" placeholder="请选择本次任务结束时间" type="datetime" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <div style="font-size: 12rem;font-weight: 400;color: #999999;margin-top: -22rem;margin-bottom: 18rem">提交后任务立刻开始</div>
        <el-row>
          <el-col :span="7" style="min-width: 385rem">
            <el-form-item label="单人最低合格率" prop="rate">
              <div style="width: 50%">
                <el-input v-model.number="form.rate" maxlength="3" placeholder="请输入单人最低合格率" style="min-width: 120rem">
                  <i slot="suffix" style="margin-right: 12rem">%</i>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="7" style="min-width: 385rem">
            <el-form-item label="单人最高可辅导人数" prop="max_people">
              <div style="width: 50%">
                <el-input v-model.number="form.max_people" maxlength="4" placeholder="请输入单人最高可辅导人数" style="min-width: 120rem"></el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="适用校区" prop="school_id">
          <el-select v-model="form.school_id" placeholder="请选择适用校区" @change="schoolChange">
            <el-option v-for="item in arrObj.school" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="适用范围" prop="type">
          <el-radio v-model="form.type" label="1">所有年级</el-radio>
          <el-radio v-model="form.type" label="2">单个年级</el-radio>
          <el-radio v-model="form.type" label="3">单个教师</el-radio>
        </el-form-item>
        <el-form-item v-if="form.type === '2'" label="适用年级" prop="grade_id">
          <el-select v-model="form.grade_id" placeholder="请选择适用年级">
            <el-option v-for="item in arrObj.action_grade" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="form.type === '3'" label="适用教师" prop="teacher_id">
          <el-select v-model="form.teacher_id" :remote-method="remoteMethod" filterable="" placeholder="请选择适用教师" remote="">
            <el-option v-for="(item) in searchTeacher" :key="item.teacher_id" :label="item.name+`(${item.mobile})`" :value="item.teacher_id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      
    </div>
  
</edit-template>
</template>

<script>
export default {
	_config:{"route":{"path":"add","meta":{"title":"新增","style":1}}},
  name: "add",
  data() {
    return {
      form: {
        grade_id: '',
        teacher_id: '',
        type: '1'
      },
      rules: {
        name: [
          {required: true, message: '请输入任务名称', trigger: 'change'},
        ],
        end_at: [
          {required: true, message: '请选择本次任务结束时间', trigger: 'change'},
        ],
        rate: [
          {required: true, message: '请输入单人最低合格率', trigger: 'change'},
          {type: "number", min: 1, max: 100, message: '最低合格率仅可填写1-100的整数', trigger: 'change'}
        ],
        max_people: [
          {required: true, message: '请输入单人最高可辅导人数', trigger: 'blur'},
          {type: "number", min: 1, max: 1000, message: '仅可填写1-1000的整数', trigger: 'change'}
        ],
        school_id: [
          {required: true, message: '请选择适用校区', trigger: 'change'}
        ],
        type: [
          {required: true, message: '请选择适用范围', trigger: 'change'}
        ],
        grade_id: [
          {required: true, message: '请选择适用年级', trigger: 'change'}
        ],
        teacher_id: [
          {required: true, message: '请选择适用教师', trigger: 'change'}
        ]
      },
      arrObj: {
        grade: [],
        school: []
      },
      searchTeacher: []
    }
  },
  methods: {
    submitted() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.$_axios2.post('api/coach-task/task-check', this.form, {logic: 1}).then((res) => {
            if (typeof res.data === 'object' && res.data !== null && !Array.isArray(res.data)) {
              if (res.data.status === 1) {
                this.$confirm("当前适用范围内的教师在其他任务内，是否提交？", {
                  confirmButtonText: "继续提交",
                  cancelButtonText: "点错了",
                  type: "warning",
                }).then(() => {
                  this.$_axios2.post('api/coach-task/add-task', this.form, {logic: 1}).then(res => {
                    this.$message.success('提交成功')
                    this.$store.commit('setPage', 1)
                    this.$router.back()
                  })
                }).catch(() => {
                })
              }
              if (res.data.status === 0) {
                this.$_axios2.post('api/coach-task/add-task', this.form, {logic: 1}).then(() => {
                  this.$message.success('提交成功')
                  this.$store.commit('setPage', 1)
                  this.$router.back()
                })
              }
            } else {
              this.$_axios2.post('api/coach-task/add-task', this.form, {logic: 1}).then(() => {
                this.$message.success('提交成功')
                this.$store.commit('setPage', 1)
                this.$router.back()
              })
            }
          })

        }
      })
    },
    cancel() {
      this.$confirm("是否取消新增", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$router.back();
      }).catch(() => {
      });
    },
    remoteMethod(val) {
      this.$_axios2.get('api/teacher/search-teacher', {params: {keyword: val, school_id: this.form.school_id}}).then(res => {
        this.searchTeacher = res.data.data
      })
    },
    schoolChange() {
      this.searchTeacher = []
      this.form.teacher_id = ''
    }
  },
  created() {
    this.$_axios2.get('api/common/school').then(res => {
      this.arrObj = res.data.data
      this.$forceUpdate()
    })
  }
}
</script>

<style lang="scss" scoped>
.body {
  box-sizing: border-box;
  min-height: 366rem;
  margin-top: 12rem;
  padding: 24rem;
  border-radius: 8rem 8rem 8rem 8rem;
  background: #FFFFFF;
}

.el-form-item__label {
  font-size: 16rem;
  font-weight: 400;
  color: #666666;
}
</style>
